// Generated by Framer (25dafa9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I1761:1866;1752:1089"];

const variantClassNames = {"I1761:1866;1752:1089": "framer-v-1vp79of"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "I1761:1866;1752:1089", image: WcvNVgrfn = {src: new URL("assets/512/7DjCPxZHnatP7JBvhPNJRN3AMo.png", import.meta.url).href, srcSet: `${new URL("assets/512/7DjCPxZHnatP7JBvhPNJRN3AMo.png", import.meta.url).href} 512w, ${new URL("assets/1024/7DjCPxZHnatP7JBvhPNJRN3AMo.png", import.meta.url).href} 1024w, ${new URL("assets/7DjCPxZHnatP7JBvhPNJRN3AMo.png", import.meta.url).href} 1172w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I1761:1866;1752:1089", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YlTkh", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "stretch", sizes: "293px", ...toResponsiveImage(WcvNVgrfn)}} className={cx("framer-1vp79of", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I1761:1866;1752:1089"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(225, 230, 233)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YlTkh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YlTkh .framer-cp43cp { display: block; }", ".framer-YlTkh .framer-1vp79of { height: 201px; position: relative; width: 293px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 201
 * @framerIntrinsicWidth 293
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WcvNVgrfn":"image"}
 */
const FramerpJeOq7sjZ: React.ComponentType<Props> = withCSS(Component, css, "framer-YlTkh") as typeof Component;
export default FramerpJeOq7sjZ;

FramerpJeOq7sjZ.displayName = "Resource Card";

FramerpJeOq7sjZ.defaultProps = {height: 201, width: 293};

addPropertyControls(FramerpJeOq7sjZ, {WcvNVgrfn: {__defaultAssetReference: "data:framer/asset-reference,7DjCPxZHnatP7JBvhPNJRN3AMo.png?originalFilename=Rectangle+1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerpJeOq7sjZ, [])